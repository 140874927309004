<template>
  <div>
    <ReservationsPage v-if="showReservationPage" />
    <div v-if="noModulesEnabled" class="page-container">
      <br />
      <h1>
        This organisation does not have Workplace and Sensor modules
        enabled
      </h1>
    </div>
  </div>
</template>

<script>
import ReservationsPage from '@/pages/ReservationsPage'
import { mapState } from 'vuex'

export default {
  name: 'WorkplaceReservationPage',
  components: {
    ReservationsPage
  },
  data() {
    return {}
  },
  computed: {
    showReservationPage() {
      return (
        !this.organisationModules ||
        this.organisationModules.workplace
      )
    },
    showSensorsDataPage() {
      return (
        this.organisationModules &&
        !this.organisationModules.workplace &&
        this.organisationModules.sensor
      )
    },
    noModulesEnabled() {
      return (
        this.organisationModules &&
        !this.organisationModules.loading &&
        !this.organisationModules.sensor &&
        !this.organisationModules.workplace
      )
    },
    ...mapState('common', ['organisationModules'])
  },
  watch: {
    organisationModules() {
      if (
        this.organisationModules &&
        !this.organisationModules.workplace &&
        this.organisationModules.sensor
      ) {
        this.$router.push('/sensors-data')
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
