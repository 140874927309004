<template>
  <div class="page-container">
    <section v-if="isMapAvailable" class="section-settings">
      <div class="container">
        <div class="row topbar">
          <alert />
          <h1 class="title-1">
            {{ $t('freeDesks') }}
          </h1>
          <div class="navigation-arrows-mobile">
            <a
              align-h="center"
              class="btn btn-outline-secondary"
              variant="primary"
              @click="handleNavigation('prev')"
              ><font-awesome-icon icon="chevron-left" />
            </a>
            <b-button
              class="button-timepicker mx-1 rounded"
              variant="primary"
              @click="$bvModal.show('modal-changedate')"
            >
              <span v-if="startTime && endTime">
                {{
                  startTime
                    | moment('timezone', buildingTimezone, 'ddd')
                }}
                {{
                  startTime
                    | moment(
                      'timezone',
                      buildingTimezone,
                      'D MMMM HH:mm'
                    )
                }}
                -
                {{
                  endTime
                    | moment('timezone', buildingTimezone, 'HH:mm')
                }}
              </span>
              <font-awesome-icon
                class="calendar-icon"
                icon="calendar-alt"
              />
            </b-button>
            <a
              align-h="center"
              class="btn btn-outline-secondary"
              variant="primary"
              @click="handleNavigation('next')"
              ><font-awesome-icon icon="chevron-right" />
            </a>
          </div>
          <div class="floorAndEditButton">
            <template v-if="floors.length > 1">
              <floors-dropdown
                v-bind="{
                  floors,
                  currentFloor
                }"
                :on-floor-selection="handleFloorSelection"
              />
            </template>

            <template v-if="isDashboardAdmin">
              <b-button
                variant="outline-secondary"
                class="buttonMapEdit ml-1"
                @click="changeMode()"
              >
                {{ $t('edit') }}
              </b-button>
            </template>
          </div>
        </div>

        <div class="subtitle">
          {{ $t('freeDesksSubtitle') }}
        </div>

        <div
          class="row justify-content-start justify-content-md-center"
        >
          <div class="col-12">
            <b-tabs v-model="activetab" pills class="map-tabs">
              <b-tab active>
                <template v-slot:title>
                  <font-awesome-icon icon="layer-group" />
                </template>
                <b-card-text>
                  <div
                    v-if="allAttributes.length"
                    class="filters-row tar"
                  >
                    <div class="floorplan-values">
                      <attributes-dropdown
                        :options="allAttributes"
                        :selected="attributeFilters"
                        @update-selection="
                          updateSelectedFiltersForFloors
                        "
                      />
                    </div>
                  </div>
                  <template v-if="editMode">
                    <SvgEditor
                      :current-floor="currentFloor"
                      :desks="desks"
                      @updateMode="updateMode"
                    />
                  </template>
                  <template v-else>
                    <SvgMap
                      v-bind="{
                        currentFloor,
                        activeFloorDesks,
                        selectedObject,
                        currentUser,
                        desks,
                        floors,
                        rooms,
                        organisation,
                        updatedObjectId,
                        currentUserId
                      }"
                      :current-rooms="filteredCurrentRooms"
                      @updateSelectedObject="updateSelectedObject"
                    />
                  </template>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <font-awesome-icon icon="list" />
                </template>
                <b-card-text>
                  <div
                    class="filters-row tar d-flex flex-wrap align-items-center"
                  >
                    <div
                      class=" d-flex align-items-center flex-wrap mr-2"
                    >
                      <attributes-dropdown
                        v-if="allAttributes.length"
                        class="mr-2"
                        :options="allAttributes"
                        :selected="attributeFilters"
                        @update-selection="
                          updateSelectedFiltersForFloors
                        "
                      />
                      <span
                        class="floorplan-values--item mr-2"
                        :class="{
                          current: currentValue == 'desks'
                        }"
                        @click="currentValue = 'desks'"
                        >{{ $t('desks') }}</span
                      >
                      <span
                        class="floorplan-values--item"
                        :class="{
                          current: currentValue == 'rooms'
                        }"
                        @click="currentValue = 'rooms'"
                        >{{ $t('rooms') }}</span
                      >
                    </div>
                    <input
                      id="table-filter"
                      ref="filter"
                      type="text"
                      class="form-control filter-input"
                      :placeholder="$t('filterTable')"
                      @keyup="filterTable"
                    />
                  </div>

                  <div
                    v-if="
                      Object.keys(currentFloor).length &&
                        currentValue == 'desks'
                    "
                    class="table-responsive"
                  >
                    <table
                      ref="table"
                      class="table-style table-settings"
                    >
                      <thead>
                        <tr>
                          <th>
                            {{ $t('desk') }}
                          </th>
                          <th>
                            {{ $t('floor') }}
                          </th>
                          <th>
                            {{ $t('room') }}
                          </th>
                          <th>
                            {{ $t('reservedFor') }}
                          </th>
                          <th>
                            {{ $t('reservationSubject') }}
                          </th>
                          <th>
                            {{ $t('attributes') }}
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(desk, idx) in activeFloorDesks"
                        >
                          <template>
                            <tr
                              v-if="desk.reservable"
                              :key="`${desk.id}__${idx}`"
                              :data-val="
                                desk.name +
                                  ' ' +
                                  desk.roomName +
                                  ' ' +
                                  desk.floorName +
                                  ' ' +
                                  (desk.status !== 'FREE'
                                    ? desk.reservedForUserFirstName +
                                      ' ' +
                                      desk.reservedForUserLastName +
                                      ' ' +
                                      desk.reservedForUser
                                    : '')
                              "
                            >
                              <td
                                class="mobile-flex"
                                :data-label="$t('desk') + ':'"
                              >
                                <span>
                                  <span class="mobile-bigger">{{
                                    desk.name
                                  }}</span>
                                  <br />
                                  <span class="mobile-show">{{
                                    desk.floorName
                                  }}</span>
                                </span>
                                <button
                                  v-if="
                                    desk.status === OCCUPANCY.FREE &&
                                      !reservedRoomIds.includes(
                                        desk.roomId
                                      )
                                  "
                                  class="btn btn-secondary mobile-show"
                                  @click="
                                    ;(selectedObject = desk),
                                      $bvModal.show('modal-reserve')
                                  "
                                >
                                  {{ $t('reserve') }}
                                </button>
                                <span
                                  v-else
                                  class="desk-stat mobile-show"
                                  :class="{
                                    'text-orange':
                                      desk.status ===
                                      OCCUPANCY.RESERVED,
                                    'text-red':
                                      desk.status ===
                                      OCCUPANCY.OCCUPIED
                                  }"
                                  >{{ getReservationLabel(desk) }}
                                  <!-- Hide question-circle icon from mobile screen on desks -->
                                  <!-- &nbsp;
                                  <font-awesome-icon
                                    v-if="
                                      desk.status ===
                                        OCCUPANCY.RESERVED
                                    "
                                    icon="question-circle"
                                    class="desk-stat mobile-show"
                                    :title="$t('availability')"
                                    @click="
                                      ;(selectedObject = desk),
                                        $bvModal.show(
                                          'modal-availability'
                                        )
                                    "
                                  >
                                  </font-awesome-icon> -->
                                </span>
                              </td>
                              <td
                                class="mobile-hide"
                                :data-label="$t('floor') + ':'"
                              >
                                {{ desk.floorName }}
                              </td>
                              <td
                                class="mobile-hide"
                                :data-label="$t('room') + ':'"
                              >
                                {{ desk.roomName }}
                              </td>

                              <td
                                class="mobile-50"
                                :class="{
                                  'mobile-hide':
                                    desk.reservedForUser == null
                                }"
                                :data-label="$t('reservedFor') + ':'"
                              >
                                {{ getDeskReservedFor(desk) }}
                              </td>

                              <td class="mobile-50">
                                <div
                                  v-if="isAdmin"
                                  v-b-tooltip.hover.right="
                                    desk.reservationSubject
                                  "
                                  class="texthide "
                                >
                                  {{ desk.reservationSubject }}
                                </div>
                                <div
                                  v-if="
                                    desk.visibleToOthers &&
                                      !isAdmin &&
                                      desk.privacySettings ===
                                        'show all details'
                                  "
                                  v-b-tooltip.hover.right="
                                    desk.reservationSubject
                                  "
                                  class="texthide "
                                >
                                  {{ desk.reservationSubject }}
                                </div>
                                <div
                                  v-if="
                                    desk.reservedForUser ===
                                      userDetail &&
                                      !isAdmin &&
                                      !desk.visibleToOthers &&
                                      desk.privacySettings ===
                                        'show all details'
                                  "
                                >
                                  {{ desk.reservationSubject }}
                                </div>
                              </td>
                              <td
                                :data-label="$t('attributes') + ':'"
                              >
                                <div
                                  v-if="desk.attributes"
                                  class="d-flex flex-wrap "
                                >
                                  <template v-if="desk.attributes">
                                    <base-pill
                                      v-for="(deskAttribute,
                                      index) in desk.attributes"
                                      :key="`desk-attribue-${index}`"
                                      :item="deskAttribute"
                                    />
                                  </template>
                                  <div v-else>
                                    &mdash;
                                  </div>
                                </div>
                              </td>
                              <td
                                class="mobile-50"
                                :class="{
                                  'mobile-hide':
                                    desk.status === OCCUPANCY.FREE &&
                                    !reservedRoomIds.includes(
                                      desk.roomId
                                    )
                                }"
                                data-label
                              >
                                <button
                                  v-if="
                                    desk.status === OCCUPANCY.FREE &&
                                      !reservedRoomIds.includes(
                                        desk.roomId
                                      )
                                  "
                                  class="btn btn-secondary mobile-hide"
                                  @click="
                                    ;(selectedObject = desk),
                                      $bvModal.show('modal-reserve')
                                  "
                                >
                                  {{ $t('reserve') }}
                                </button>
                                <span
                                  v-else
                                  class="desk-stat mobile-hide"
                                  :class="{
                                    'text-orange':
                                      desk.status ===
                                      OCCUPANCY.RESERVED,
                                    'text-red':
                                      desk.status ===
                                      OCCUPANCY.OCCUPIED
                                  }"
                                  >{{ getReservationLabel(desk) }}
                                  &nbsp;
                                  <font-awesome-icon
                                    v-if="
                                      desk.status ===
                                        OCCUPANCY.RESERVED
                                    "
                                    icon="question-circle"
                                    class="mobile-hide"
                                    :title="$t('availability')"
                                    @click="
                                      ;(selectedObject = desk),
                                        $bvModal.show(
                                          'modal-availability'
                                        )
                                    "
                                  >
                                  </font-awesome-icon>
                                </span>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div
                    v-if="
                      Object.keys(currentFloor).length &&
                        currentValue == 'rooms'
                    "
                    class="table-responsive"
                  >
                    <table
                      ref="table"
                      class="table-style table-settings"
                    >
                      <thead>
                        <tr>
                          <th>
                            {{ $t('room') }}
                          </th>
                          <th>
                            {{ $t('floor') }}
                          </th>
                          <th>
                            {{ $t('reserved') }}
                          </th>
                          <th>
                            {{ $t('reservationSubject') }}
                          </th>
                          <th>
                            {{ $t('attributes') }}
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(room, idx) in filteredCurrentRooms"
                        >
                          <tr
                            :key="`${room.id}__${idx}`"
                            :data-val="
                              `${room.name} ${room.floorName} ${room.reservedForUserFirstName} ${room.reservedForUserLastName}`
                            "
                          >
                            <td
                              class="mobile-flex"
                              :data-label="$t('room') + ':'"
                            >
                              <span>
                                <span class="mobile-bigger">{{
                                  room.name
                                }}</span>
                                <br />
                                <span class="mobile-show">{{
                                  room.floorName
                                }}</span>
                              </span>
                              <button
                                v-if="
                                  room.status === OCCUPANCY.FREE &&
                                    room.reservable
                                "
                                class="btn btn-secondary mobile-show"
                                @click="
                                  ;(selectedObject = room),
                                    $bvModal.show('modal-reserve')
                                "
                              >
                                {{ $t('reserve') }}
                              </button>
                              <span
                                v-if="!room.reservable"
                                class="text-orange desk-stat mobile-show"
                                >{{ $t('notReservable') }}</span
                              >
                              <span
                                v-if="
                                  room.status === OCCUPANCY.RESERVED
                                "
                                class="text-orange desk-stat mobile-show"
                                >{{ $t('reserved') }}
                                {{ $t('until') }}
                                {{
                                  room.reservationEndDatetime
                                    | moment(
                                      'timezone',
                                      buildingTimezone,
                                      'HH:mm'
                                    )
                                }}
                                <!-- Hide question-circle icon from mobile screen on rooms-->
                                <!-- &nbsp;
                                <font-awesome-icon
                                  class="mobile-show"
                                  icon="question-circle"
                                  :title="$t('availability')"
                                  @click="
                                    ;(selectedObject = room),
                                      $bvModal.show(
                                        'modal-availability'
                                      )
                                  "
                                >
                                </font-awesome-icon> -->
                              </span>
                              <span
                                v-if="
                                  room.status === OCCUPANCY.OCCUPIED
                                "
                                class="text-red desk-stat mobile-show"
                                >{{ $t('occupied') }}/{{
                                  $t('reserved')
                                }}</span
                              >
                            </td>
                            <td
                              class="mobile-hide"
                              :data-label="$t('floor') + ':'"
                            >
                              {{ room.floorName }}
                            </td>
                            <td
                              :class="{
                                'mobile-hide':
                                  room.reservedForUser == null
                              }"
                              class="mobile-50"
                              :data-label="$t('reservedFor') + ':'"
                            >
                              {{ getRoomReservedFor(room) || '' }}
                            </td>
                            <td class="mobile-50">
                              <div
                                v-if="isAdmin"
                                v-b-tooltip.hover.right="
                                  room.reservationSubject
                                "
                                class="texthide"
                              >
                                {{ room.reservationSubject }}
                              </div>
                              <div
                                v-if="
                                  room.visibleToOthers &&
                                    !isAdmin &&
                                    room.privacySettings ===
                                      'show all details'
                                "
                                v-b-tooltip.hover.right="
                                  room.reservationSubject
                                "
                                class="texthide"
                              >
                                {{ room.reservationSubject }}
                              </div>
                              <div
                                v-if="
                                  room.reservedForUser ===
                                    userDetail &&
                                    !isAdmin &&
                                    !room.visibleToOthers &&
                                    room.privacySettings ===
                                      'show all details'
                                "
                              >
                                {{ room.reservationSubject }}
                              </div>
                            </td>
                            <td :data-label="$t('attributes') + ':'">
                              <div
                                v-if="room.attributes"
                                class="d-flex flex-wrap"
                              >
                                <template v-if="room.attributes">
                                  <base-pill
                                    v-for="(roomAttribute,
                                    key) in room.attributes"
                                    :key="`room-attribute-${key}`"
                                    :item="roomAttribute"
                                  />
                                </template>
                                <div v-else>
                                  &mdash;
                                </div>
                              </div>
                            </td>
                            <td
                              data-label
                              class="mobile-50"
                              :class="{
                                'mobile-hide':
                                  room.status !==
                                    OCCUPANCY.RESERVED &&
                                  room.status !== OCCUPANCY.OCCUPIED
                              }"
                            >
                              <!-- TODO: make room reservation timeslots apply in list mode -->
                              <button
                                v-if="
                                  room.status === OCCUPANCY.FREE &&
                                    room.reservable
                                "
                                class="btn btn-secondary mobile-hide"
                                @click="
                                  ;(selectedObject = room),
                                    $bvModal.show('modal-reserve')
                                "
                              >
                                {{ $t('reserve') }}
                              </button>
                              <span
                                v-if="!room.reservable"
                                class="text-orange desk-stat mobile-hide"
                                >{{ $t('notReservable') }}</span
                              >
                              <span
                                v-if="
                                  room.status === OCCUPANCY.RESERVED
                                "
                                class="text-orange desk-stat mobile-hide"
                                >{{ $t('reserved') }}
                                {{ $t('until') }}
                                {{
                                  room.reservationEndDatetime
                                    | moment(
                                      'timezone',
                                      buildingTimezone,
                                      'HH:mm'
                                    )
                                }}
                                &nbsp;
                                <font-awesome-icon
                                  class="mobile-hide"
                                  icon="question-circle"
                                  :title="$t('availability')"
                                  @click="
                                    ;(selectedObject = room),
                                      $bvModal.show(
                                        'modal-availability'
                                      )
                                  "
                                >
                                </font-awesome-icon>
                              </span>
                              <span
                                v-if="
                                  room.status === OCCUPANCY.OCCUPIED
                                "
                                class="text-red desk-stat"
                                >{{ $t('occupied') }}/{{
                                  $t('reserved')
                                }}</span
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      <h1 v-if="loadingReady" class="no-map-available">
        {{ $t('mapNotAvailable') }}
      </h1>
    </section>
    <reservation-modal
      v-bind="{
        selectedObject,
        startTime,
        endTime,
        showReservationSubject
      }"
      :is-busy="busy"
      @reserve="reserveObject"
    />
    <reservation-timepicker-modal
      v-bind="{ reservationSettings, navigated, navigatedDate }"
      :raw-timeslots="buildingTimeslots"
      show-hints
    />
    <todays-availability-modal
      v-bind="{
        selectedObject,
        startTime,
        endTime,
        buildingTimezone,
        buildingTimeslots
      }"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import columnSortable from 'vue-column-sortable'
import vueMoment from 'vue-moment'
import _each from 'lodash/each'
import moment from 'moment-timezone'

import Alert from '@/components/common/Alert'
import FloorsDropdown from '@/components/common/FloorsDropdown'
import ReservationTimepickerModal from '@/components/modals/ReservationTimepickerModal'
import SvgEditor from '@/components/common/SvgEditor'
import SvgMap from '@/components/common/SvgMap'
import ReservationModal from '@/components/modals/ReservationModal'
import TodaysAvailabilityModal from '@/components/modals/TodaysAvailabilityModal'

import ReservationMixin from '@/mixins/ReservationsMixin'
import { filterTableMixin } from '@/mixins/mixins'
import AttributesMixin from '@/mixins/AttributesMixin'
import OkkuApi from '@/services/OkkuApi'
import {
  SET_ALERT,
  SET_TIMEPICKER_STATE
} from '@/store/modules/common/mutationTypes'

import {
  OCCUPANCY,
  OBJECTS_TYPES,
  DATE_TIME_NAVIGATION
} from '@/constants'

Vue.use(vueMoment)

export default {
  name: 'ReservationsPage',
  components: {
    Alert,
    SvgEditor,
    SvgMap,
    ReservationTimepickerModal,
    ReservationModal,
    TodaysAvailabilityModal,
    FloorsDropdown,
    AttributesDropdown: () =>
      import('@/components/common/AttributesDropdown.vue'),
    BasePill: () => import('@/components/common/BasePill.vue')
  },
  directives: {
    columnSortable
  },
  // Include attributes mixin for easily handling of attributes
  mixins: [filterTableMixin, AttributesMixin, ReservationMixin],
  metaInfo: {
    meta: [
      {
        vmid: 'viewport',
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
      }
    ]
  },
  data() {
    return {
      sortKey: 'status',
      currentValue: 'desks',
      activetab: 0,
      maxReservations: null,
      buildingTimeslots: [],
      startTime: '',
      endTime: '',
      availableDesks: [],
      currentFloor: {},
      selectedObject: {},
      currentRoom: [],
      currentRooms: [],
      roomStatuses: [],
      connection: '',
      editMode: false,
      isMapAvailable: false,
      busy: false,
      OCCUPANCY,
      attributeFilters: [],
      updatedObjectId: '',
      navigated: false,
      navigatedDate: ''
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerAdmin: '$isCustomerAdmin',
      reservationSettings: 'organisationReservationSettings',
      userinfo: '$userInfo',
      loadingReady: 'ready',
      keycloakUserProfile: 'keycloakUserProfile'
    }),
    ...mapState('common', [
      'floors',
      'desks',
      'rooms',
      'isKiosk',
      'timepicker'
    ]),
    ...mapGetters('common', {
      buildingTimezone: 'buildingTimezone',
      roomReservationSubject: 'allowRoomReservationSubject',
      deskReservationSubject: 'allowDeskReservationSubject',
      userDetail: 'userDetail',
      isAdmin: 'isAdmin',
      reservationsettings: 'reservationsettings'
    }),
    activeFloorDesks() {
      return this.availableDesks
        ? this.availableDesks.filter(desk => {
            const isDeskAvailableOnFloor =
              this.currentFloor &&
              this.currentFloor.id === desk.floorId
            return this.filterValuesByAttributes(
              isDeskAvailableOnFloor,
              desk
            )
          })
        : []
    },
    filteredCurrentRooms() {
      return this.currentRooms.filter(room => {
        const isRoomAvailableOnFloor =
          this.currentFloor && this.currentFloor.id === room.floorId
        return this.filterValuesByAttributes(
          isRoomAvailableOnFloor,
          room
        )
      })
    },
    reservedRoomIds() {
      return this.filteredCurrentRooms
        .filter(room => room.status === OCCUPANCY.RESERVED)
        .map(room => room.id)
    },
    findMyColleagues() {
      return this.userinfo.groups[this.organisation].findMyColleagues
    },
    currentUser() {
      return this.userinfo.user
    },
    currentUserId() {
      return this.userinfo.userId
    },
    showReservationSubject() {
      if (
        this.selectedObject.type === 'DESK' &&
        this.deskReservationSubject
      ) {
        return true
      }
      if (
        this.selectedObject.type === 'ROOM' &&
        this.roomReservationSubject
      ) {
        return true
      }
      return false
    }
  },
  watch: {
    currentValue() {
      this.resetAttributeFilter()
    },
    $lastUpdate() {
      this.getDeskStatus()
    },
    organisation() {
      this.subscribeToUpdates(this.organisation)
    },
    floors() {
      if (this.floors.length > 0) {
        const [currentFloor] = this.floors
        this.currentFloor = currentFloor
        this.isMapAvailable = true
      }
    },
    currentFloor() {
      this.getCurrentFloorRooms()
    },
    desks(newDesks, oldDesks) {
      // fetch desk status when desks data changes e.g. edited from SvgEditor
      if (oldDesks && oldDesks.length) {
        this.getDeskStatus()
      }
    },
    timepicker(nextState, prevState) {
      if (
        prevState.date !== null &&
        nextState.date !== prevState.date
      ) {
        this.getBuildingTimeslots(nextState.date)
        return
      }
      if (
        this.startTime !== nextState.startTime ||
        this.endTime !== nextState.endTime
      ) {
        this.startTime = nextState.startTime
        this.endTime = nextState.endTime
        this.checkAvailability()
      }
    }
  },
  destroyed() {
    if (this.connection) {
      this.connection.close()
    }
  },
  async mounted() {
    // Get all attributes through the Attribute Mixin using the API
    await this.getAllAttributes()
    // allow timepicker to enforce its rules
    if (
      this.timepicker.startTime != null &&
      this.timepicker.endTime != null
    ) {
      this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
        startTime: this.timepicker.startTime,
        endTime: this.timepicker.endTime,
        date: this.timepicker.date
      })
    }
    // If floors array is filled, select the current and show the map
    if (this.floors.length > 0) {
      this.isMapAvailable = true
      const [currentFloor] = this.floors
      const currentBuilding = localStorage.getItem('BUILDING')
      const lastSelectedFloor = JSON.parse(
        localStorage.getItem('floor') || null
      )
      if (
        lastSelectedFloor &&
        currentBuilding === lastSelectedFloor.building
      ) {
        this.currentFloor = lastSelectedFloor
      } else {
        this.currentFloor = currentFloor
      }
    }

    if (this.organisation) {
      this.subscribeToUpdates(this.organisation)
      this.getBuildingTimeslots(this.timepicker.date || undefined)
    }

    if (window.innerWidth < 767) {
      this.activetab = 1
    }
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.selectedObject = {}
    })

    if (Object.values(this.timepicker).every(Boolean)) {
      this.startTime = this.timepicker.startTime
      this.endTime = this.timepicker.endTime
      this.checkAvailability()
    }
  },
  methods: {
    handleNavigation(type) {
      this.maxReservations = Number(
        this.reservationSettings?.futureDayMaximum
      )
      const [maxReservations] =
        this.keycloakUserProfile.attributes
          .futureReservationMaximum || []
      if (maxReservations >= this.maxReservations) {
        this.maxReservations = Number(maxReservations)
      }
      let maxDaysLimit = null
      if (this.maxReservations > 0) {
        maxDaysLimit = moment(new Date())
          .add(this.maxReservations, 'Days')
          .endOf('day')
          .toISOString()
      }
      this.navigated = true
      const { date, startTime, endTime } = this.timepicker
      let newDate = ''
      let newStartTime = ''
      let newEndTime = ''

      if (type === DATE_TIME_NAVIGATION.NEXT) {
        newDate = moment(date)
          .tz('UTC')
          .add(1, 'Days')
          .toISOString()
        newStartTime = moment(startTime)
          .tz('UTC')
          .add(1, 'Days')
          .toISOString()
        newEndTime = moment(endTime)
          .tz('UTC')
          .add(1, 'Days')
          .toISOString()
      } else if (type === DATE_TIME_NAVIGATION.PREVIOUS) {
        const dateTime = moment(this.startTime)
          .tz('UTC')
          .startOf('day')
          .subtract(1, 'Days')
          .toISOString()

        if (
          moment(dateTime).isBefore(
            moment()
              .tz('UTC')
              .startOf('day')
              .toISOString()
          )
        ) {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('pastDate'),
            variant: 'warning'
          })
          return
        }
        newDate = moment(date)
          .tz('UTC')
          .subtract(1, 'Days')
          .toISOString()
        newStartTime = moment(startTime)
          .tz('UTC')
          .subtract(1, 'Days')
          .toISOString()
        newEndTime = moment(endTime)
          .tz('UTC')
          .subtract(1, 'Days')
          .toISOString()
      }
      if (newStartTime > maxDaysLimit) {
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 3,
          text: this.$t('futureDate'),
          variant: 'danger'
        })
        return
      }
      this.navigatedDate = newDate
      // Update store dates
      this.startTime = newStartTime

      this.endTime = newEndTime
      this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
        date: newDate,
        startTime: newStartTime,
        endTime: newEndTime
      })
      this.checkAvailability()
    },
    filterValuesByAttributes(isAvailable = false, currentData) {
      if (this.attributeFilters && this.attributeFilters.length) {
        if (
          isAvailable &&
          currentData.attributes &&
          currentData.attributes.length
        ) {
          return this.attributeFilters.every(
            id =>
              currentData.attributes.findIndex(
                attribute => attribute.id === id
              ) !== -1
          )
        }
        return false
      }
      return isAvailable
    },
    resetAttributeFilter() {
      this.attributeFilters = []
    },
    updateSelectedFiltersForFloors(val) {
      this.attributeFilters = val
    },
    handleFloorSelection(floor) {
      const building = localStorage.getItem('BUILDING')
      localStorage.setItem(
        'floor',
        JSON.stringify({ ...floor, building })
      )
      this.currentFloor = floor
      this.checkAvailability()
      this.selectedObject = {}

      const el = document.getElementsByClassName('active img-desk')
      if (el && el.length) {
        el[0].remove()
      }
    },
    getReservationLabel(desk) {
      if (!desk.reservable) {
        return this.$t('canNotBeReservedThisTime')
      }
      if (desk.status === OCCUPANCY.RESERVED) {
        return `${this.$t('reserved')} ${this.$t('until')} ${moment(
          desk.reservationEndDatetime
        )
          .tz(this.buildingTimezone)
          .format('HH:mm')}`
      }
      if (desk.status === OCCUPANCY.OCCUPIED) {
        return `${this.$t('occupied')} / ${this.$t('reserved')}`
      }
      return ''
    },
    updateMode(editMode) {
      this.editMode = editMode
    },
    changeMode() {
      if (this.editMode) {
        this.editMode = false
      } else {
        this.editMode = true
      }
    },
    orderBy(fn) {
      this.availableDesks.sort(fn)
    },
    updateSelectedObject(selectedObject) {
      this.selectedObject = selectedObject
    },
    checkAvailability() {
      this.getDeskStatus()
      this.getRoomStatus().then(() => {
        this.getCurrentFloorRooms()
      })
    },
    subscribeToUpdates(path) {
      if (this._isDestroyed) {
        return
      }
      const wsUrl =
        process.env.VUE_APP_OKKU_API_WEBSOCKET_CONNECTION ||
        `wss://${window.location.hostname}:${window.location.port}`
      console.log(`Subscribing to ${wsUrl} @ ${path}`)
      this.connection = new WebSocket(wsUrl)
      const { connection } = this
      connection.onopen = () => {
        console.log('connected')
        setTimeout(() => {
          connection.send(`SUB:${path}`)
          console.log(`Subscribed to ${path}`)
        }, 100)
      }
      connection.onmessage = event => {
        console.log('received', event.data)
        this.applyWebsocketUpdate(event.data)
      }
      connection.onclose = () => {
        console.log('websocket closed')
        this.subscribeToUpdates(this.organisation)
      }
      connection.onerror = error => {
        console.log('failed to connect', error)
      }
    },
    getRoomStatus() {
      const query = `?from=${this.startTime}&to=${this.endTime}&sensorGap=15`
      return OkkuApi.getRoomStatus(query).then(roomStatuses => {
        this.roomStatuses = roomStatuses
      })
    },
    updateDeskById({ id, status, firstName, lastName, userId }) {
      const desks = [...this.availableDesks]

      desks.forEach(activeDesk => {
        if (activeDesk.id === id) {
          if (!activeDesk.context) {
            activeDesk.context = {
              firstName: '',
              lastName: ''
            }
          }
          activeDesk.status = status
          activeDesk.context.firstName = firstName
          activeDesk.context.lastName = lastName
          activeDesk.context.reservedBy = null
          activeDesk.userId = userId
        }
      })

      this.availableDesks = desks
    },
    applyWebsocketUpdate(update) {
      const updateParts = update.split('/')
      const updatePath = `/${updateParts[1]}/${updateParts[2]}`
      const actionName = `${updateParts[3]}`

      // apply fixed desks
      if (
        this.organisation === updatePath &&
        actionName === 'FIX-DESKS'
      ) {
        const fixedDesks = JSON.parse(updateParts[4])
        // const event = `${updateParts[5]}`
        // if (event === 'FIXED-USER') {
        // const fixedDesks = JSON.parse(updateParts[4])

        fixedDesks.forEach(fixedDesk => {
          this.updateDeskById(fixedDesk)
        })
        // }

        // if (event === 'PLANNED-ABSENCES') {
        //   const plannedAbsences = JSON.parse(
        //     updateParts[4].length ? updateParts[4] : []
        //   )

        //   if (plannedAbsences.length) {
        //     plannedAbsences.forEach(absence => {
        //       this.updateFixDeskStatus(
        //         absence.startDateTime,
        //         absence.endDateTime,
        //         absence.regularUser
        //       )
        //     })
        //   }
        // }
      }

      // check for org/building against organisation
      if (
        this.organisation === updatePath &&
        actionName !== 'FIX-DESKS'
      ) {
        const objectId = updateParts[3]
        const action = updateParts[4]
        const startDatetime = moment(updateParts[5])
        const endDatetime = moment(updateParts[6])
        // is the update relevant to the selected interval?
        if (
          startDatetime.isBefore(moment(this.endTime)) &&
          endDatetime.isAfter(this.startTime)
        ) {
          let desk
          // Intervals match
          if (this.availableDesks) {
            desk = this.availableDesks.filter(
              availableDesk => availableDesk.id === objectId
            )
            if (desk && desk.length) {
              ;[desk] = desk
            } else {
              desk = null
            }
          }
          if (desk) {
            if (action === 'CREATE') {
              desk.status = OCCUPANCY.RESERVED
              desk.reservationStartDatetime = startDatetime
              desk.reservationEndDatetime = endDatetime
              desk.showUserDetails = false
            }
            if (action === 'CANCEL') {
              desk.status = OCCUPANCY.FREE
              desk.reservationStartDatetime = null
              desk.reservationEndDatetime = null
              delete desk.reservedForUser
              delete desk.reservedFor
            }
          } else {
            const room = this.currentRooms.find(
              r => r.id === objectId
            )
            if (room) {
              if (action === 'CREATE') {
                room.status = OCCUPANCY.RESERVED
                room.reservationStartDatetime = startDatetime
                room.reservationEndDatetime = endDatetime
                room.showUserDetails = false
                this.updatedObjectId = room.id // hack to update the SVG map
              }
              if (action === 'CANCEL') {
                room.status = OCCUPANCY.FREE
                room.reservationStartDatetime = null
                room.reservationEndDatetime = null
                delete room.reservedForUser
                delete room.reservedFor
                this.updatedObjectId = room.id // hack to update the SVG map
              }
            }
          }
        }
      }
    },

    getDeskStatus() {
      const query = `?from=${this.startTime}&to=${this.endTime}&floorId=${this.currentFloor.id}`
      return OkkuApi.getDeskStatus(query).then(data => {
        const availableDesks = data.map(deskStatus => {
          // eslint-disable-next-line no-restricted-syntax
          for (const deskDef of this.desks) {
            if (deskDef.id === deskStatus.id) {
              deskStatus.svgSnippet = deskDef.svgSnippet
              deskStatus.attributes = deskDef.attributes
              break
            }
          }
          return deskStatus
        })
        // this.availableDesks = availableDesks
        this.availableDesks = availableDesks

        const deepLinkId = this.$route.params.objectId
        if (deepLinkId) {
          // deep link to reserve a desk
          _each(this.desks, desk => {
            if (desk.id === deepLinkId) {
              this.selectedObject = desk
            }
          })
          this.$bvModal.show('modal-reserve')
          this.$route.params.objectId = undefined // ensure the deep link is not carried out after every query
        }
      })
    },
    getCurrentFloorRooms() {
      this.currentRooms = this.rooms.filter(
        room => room.floorId === this.currentFloor.id
      )
      // map with status
      _each(this.currentRooms, (room, index) => {
        _each(this.roomStatuses, roomStatus => {
          if (roomStatus.id === room.id) {
            this.currentRooms[index] = {
              ...room,
              ...roomStatus
            }
          }
        })
      })
    },
    constructQueryParams({
      repeatWeeks,
      visibleToOthers,
      reservationSubject,
      reservationAssets
    }) {
      let query = `?objectId=${this.selectedObject.id}&from=${this.startTime}&to=${this.endTime}&visibleToOthers=${visibleToOthers}`
      if (repeatWeeks) {
        query += `&repeatWeeks=${repeatWeeks}`
      }
      if (this.selectedObject.roomEmail !== 'null') {
        query += `&roomEmail=${this.selectedObject.roomEmail}`
      }
      if (reservationSubject) {
        query += `&reservationSubject=${reservationSubject}`
      }

      Object.values(reservationAssets).forEach(asset => {
        if (asset.checked) {
          query += `&${asset.name}`
        }
      })
      return query
    },
    reserveObject(data) {
      if (this.busy) return

      this.busy = true
      const { invitedUsers, reservationAssets } = data
      const { email } = data.for
      const query = this.constructQueryParams(data)

      OkkuApi.reserveObject(
        query,
        invitedUsers,
        email,
        reservationAssets
      )
        .then(async response => {
          let desk
          this.busy = false
          let { message } = response

          let variant = 'success'
          if (!response.isConsecutiveWeeksAvailable) {
            variant = 'danger'
            this.$store.commit(`common/${SET_ALERT}`, {
              seconds: true,
              text: message,
              variant
            })
            return
          }

          if (
            response.failedReservations != null &&
            response.failedReservations.length > 0
          ) {
            const dates = response.failedReservations.reduce(
              (result, date, index) =>
                `${result}${moment(date).format('DD MMMM YYYY')}${
                  index === response.failedReservations.length - 1
                    ? ''
                    : ', '
                }`,
              ''
            )
            message = `${this.$t('recurringReservationsNotPossible', {
              dates
            })}`
            variant = 'danger'
          } else if (response.shouldReview) {
            // If successfull, check if we need to ask for reviews
            this.$bvModal.show('review-modal')
          }

          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: true,
            text: message,
            variant
          })
          // apply the reservation locally
          const { keycloakUserProfile } = this
          if (this.availableDesks) {
            desk = this.availableDesks.filter(
              availableDesk =>
                availableDesk.id === this.selectedObject.id
            )
            if (desk && desk.length) {
              ;[desk] = desk
            } else {
              desk = null
            }
          }

          if (desk) {
            desk.status = OCCUPANCY.RESERVED
            desk.reservationStartDatetime = this.startTime
            desk.reservationEndDatetime = this.endTime
            desk.reservedForUser = email
            desk.showUserDetails = true
            desk.reservationSubject = data?.reservationSubject || null
            desk.visibleToOthers = data?.visibleToOthers || null
            desk.context = this.createReservationContextObject(
              desk,
              keycloakUserProfile,
              data.for,
              invitedUsers
            )
          } else {
            const room = this.currentRooms.find(
              r => r.id === this.selectedObject.id
            )
            if (invitedUsers && invitedUsers.length)
              room.isGuestInvited = true
            room.status = OCCUPANCY.RESERVED
            room.reservationStartDatetime = this.startTime
            room.reservationEndDatetime = this.endTime
            room.reservedForUser = email
            room.showUserDetails = true
            room.reservationSubject = data?.reservationSubject || null
            room.visibleToOthers = data?.visibleToOthers || null
            room.context = this.createReservationContextObject(
              room,
              keycloakUserProfile,
              data.for,
              invitedUsers
            )
          }

          // Apply room reservation status locally
          await this.updateRoomStatus(data)
          // setTimeout(this.getDeskStatus(), 50)
        })
        .catch(({ response: { data: responseData } }) => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: responseData.message,
            variant: 'danger'
          })
          this.busy = false
        })
        .finally(() => {
          this.$bvModal.hide('modal-reserve')
          if (this.isKiosk) {
            setTimeout(() => {
              this.$cookie.delete('lang')
              window.location.href = '/logout?kiosk=true'
            }, 5000)
          }
        })
    },
    updateRoomStatus(data) {
      const oldRooms = [...this.currentRooms]
      oldRooms.forEach(room => {
        if (
          room.type === OBJECTS_TYPES.ROOM &&
          room.id === this.selectedObject.id
        ) {
          room.status = OCCUPANCY.RESERVED
          room.reservationSubject = data.reservationSubject || null
          room.visibleToOthers = data?.visibleToOthers || null
        }
      })
      this.currentRooms = oldRooms
    },
    getBuildingTimeslots(date) {
      OkkuApi.getAvailableTimeslots(
        this.organisation.slice(1),
        date
      ).then(timeslots => {
        if (timeslots && timeslots.length) {
          this.buildingTimeslots = timeslots
        } else {
          this.buildingTimeslots = []
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('noTimeSlotsOnThisDay'),
            variant: 'warning'
          })
        }
      })
    },
    createReservationContextObject(
      reservationObject,
      keycloakUserProfile,
      reservedFor,
      invitedGuest
    ) {
      if (!reservationObject && !keycloakUserProfile) {
        return {}
      }

      if (reservedFor?.email === keycloakUserProfile.email) {
        reservedFor.firstName = keycloakUserProfile.firstName
        reservedFor.lastName = keycloakUserProfile?.lastName
      }
      let contextIsGuestInvited

      const contextReservedBy = {
        firstName: keycloakUserProfile.firstName,
        lastName: keycloakUserProfile?.lastName
      }

      if (invitedGuest && invitedGuest.length) {
        contextIsGuestInvited = true
      }
      const reservationContext = {
        firstName: reservedFor?.firstName,
        lastName: reservedFor?.lastName,
        reservedBy: contextReservedBy,
        isGuestInvited: contextIsGuestInvited,
        isPrefix: true
      }
      return reservationContext
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/globals/vars.scss';
@import '../assets/scss/globals/mixins.scss';
@import '../assets/scss/globals/extend.scss';

.title-1 {
  font-size: 28px;
  margin-bottom: 0;
  margin-right: 1rem;
  line-height: 1.5;

  @include r(767) {
    display: none;
  }
}

.button-timepicker {
  display: flex;
  align-items: center;

  @include r(767) {
    width: 100%;
    justify-content: center;
  }

  svg {
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;
  }
}

.subtitle {
  margin-top: 1rem;

  @include r(767) {
    display: none;
  }
}

.map-tabs::v-deep {
  margin-top: 2rem;

  @include r(767) {
    padding-top: 0;
    & > div:first-of-type {
      display: flex;
    }
  }
  .nav-link {
    color: $basecolor;
    &.active {
      background-color: $basecolor;
      color: #fff;
    }
  }

  .table-responsive {
    margin-top: 16px;
  }
}
.desk-stat {
  margin-left: 0;
}

.floorplan-values {
  font-size: 0;
  text-align: right;
  @include r(767) {
    margin-right: 0;
    width: 100%;
    margin-top: 3px;
    text-align: left;
  }
  &--item {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin: 3px;
    background-color: rgba(#fff, 1);
    transition: all $time;
    min-width: 70px;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    padding: 11px;
    border-radius: 4px;
    color: $textcolor-med;
    border: 1px solid rgba($basecolor, 0.2);
    @include r(767) {
      /*margin-left: 0;*/
      /*margin-right: 6px;*/
    }
    &.current,
    &:hover {
      background-color: $basecolor;
      color: #fff;
    }
  }
}

.topbar {
  align-content: center;
  display: flex;
  flex-flow: row wrap;
  margin: 0;

  @include r(767) {
    flex-direction: column;
    & > * {
      margin-top: 1rem;
    }
    & > *:nth-child(2) {
      margin-top: 0;
    }
  }
}

@include rm(991) {
  .nav-pills {
    white-space: nowrap;
  }
}
.btn-calendar {
  @include r(500) {
    display: none !important;
  }
}
.filters-row {
  margin-top: -40px;
  align-items: center;
  @include r(767) {
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .filter-input {
    width: 180px;
  }
  @include r(575) {
    justify-content: space-between !important;
    .filter-input {
      width: 100% !important;
      margin-top: 4px;
    }
  }

  @include r(500) {
    .form-control {
      width: 75% !important;
    }
  }
  .floorplan-values {
    display: inline-block;
    vertical-align: middle;
  }
}
.no-map-available {
  text-align: center;
  padding: 2rem;
}
.navigation-arrows-mobile {
  display: flex;
}
.floorAndEditButton {
  margin-left: auto !important;
}
.texthide {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .navigator-button {
    width: auto !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    min-width: auto !important;
  }
  .calendar-icon {
    display: none;
  }
}
</style>
