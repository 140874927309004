<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="demo-container">
    <div class="edit-toolbar">
      <span class="btn btn-grey btn-icon" @click="revertChange">
        <font-awesome-icon icon="history" />
      </span>
      <span class="btn btn-secondary" @click="saveChanges()">{{
        $t('save')
      }}</span>
      <span class="btn btn-danger" @click="cancelChanges">{{
        $t('cancel')
      }}</span>
    </div>
    <div class="canvas">
      <svg
        id="svgEdit"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 645 402"
        style="enable-background:new 0 0 645 402;"
        xml:space="preserve"
        @mousemove="onMouseMove($event)"
        @mouseup="onMouseUp($event)"
        @click="onClickItem($event, {})"
      >
        <g v-html="currentFloor.svgSnippet" />
        <g
          v-for="(item, index) in items"
          :key="index"
          :transform="`translate('${item.x}', '${item.y}')`"
        >
          <!-- item -->
          <rect
            v-b-tooltip.hover
            :x="0"
            :y="0"
            :width="item.width"
            :height="item.height"
            :fill="item.color"
            :title="item.name"
            @click="onClickItem($event, item)"
          />
          <g v-if="item.active">
            <rect
              :x="0"
              :y="0"
              :width="item.width"
              :height="item.height"
              class="ctrl-bounds"
              @click="onClickItem($event, item)"
              @_mouseout="onMouseUpRegion($event, item)"
              @mousedown="onMouseDownRegion($event, item)"
            />
            <g @mousedown="onMouseDownHandles($event, item)">
              <rect
                class="ctrl-rect"
                :width="tools.squaresize"
                :height="tools.squaresize"
                data-handleid="1"
                :x="0 - tools.squaresize / 2"
                :y="0 - tools.squaresize / 2"
              />
              <rect
                class="ctrl-rect"
                :width="tools.squaresize"
                :height="tools.squaresize"
                data-handleid="3"
                :x="item.width - tools.squaresize / 2"
                :y="0 - tools.squaresize / 2"
              />
              <rect
                class="ctrl-rect"
                :width="tools.squaresize"
                :height="tools.squaresize"
                data-handleid="7"
                :x="0 - tools.squaresize / 2"
                :y="item.height - tools.squaresize / 2"
              />
              <rect
                class="ctrl-rect"
                :width="tools.squaresize"
                :height="tools.squaresize"
                data-handleid="9"
                :x="item.width - tools.squaresize / 2"
                :y="item.height - tools.squaresize / 2"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <b-modal
      id="modal-route"
      :on-enter-key-press="
        () => {
          editMode = false
          updateMode()
        }
      "
      hide-footer
      hide-title
    >
      <div class="d-block text-center">
        <p>{{ $t('changesNotSaved') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-route')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          class="mt-1"
          primary
          @click="
            () => {
              editMode = false
              updateMode()
            }
          "
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import OkkuApi from '@/services/OkkuApi'
import {
  SET_ALERT,
  SET_DESKS
} from '@/store/modules/common/mutationTypes'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

const defaultProps = {
  hex: '#999999',
  a: 1
}

export default {
  name: 'SvgEditor',
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    desks: {
      type: Array,
      required: true
    },
    currentFloor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      width: 1110,
      height: 692,
      colors: defaultProps,
      onChangeColorListener: null,
      showcolors: false,
      movetarget: null,
      activeItemIndex: null,
      items: [],
      editMode: true,
      lastChangedItem: null,
      lastChangedAction: null,
      lastChangedX: null,
      lastChangedY: null,
      lastChangedWidth: null,
      lastChangedHeight: null,
      changesDone: false,
      tools: {
        squaresize: 8,
        min_height: 10
      }
    }
  },
  computed: {
    currentFloorDesks() {
      return this.desks.filter(
        desk => desk.floorId === this.currentFloor.id
      )
    }
  },
  watch: {
    currentFloorDesks() {
      this.createItemsArray()
    }
  },
  mounted() {
    this.createItemsArray()
  },
  methods: {
    updateMode() {
      this.$emit('updateMode', this.editMode)
    },
    createItemsArray() {
      this.items = this.currentFloorDesks.map(
        ({ id, svgSnippet: snippet, name }) => {
          const svg = new DOMParser().parseFromString(
            snippet,
            'text/xml'
          )
          return {
            id,
            name,
            x: parseFloat(
              svg.getElementsByTagName('rect')[0].getAttribute('x')
            ),
            y: parseFloat(
              svg.getElementsByTagName('rect')[0].getAttribute('y')
            ),
            width: parseFloat(
              svg
                .getElementsByTagName('rect')[0]
                .getAttribute('width')
            ),
            height: parseFloat(
              svg
                .getElementsByTagName('rect')[0]
                .getAttribute('height')
            ),
            active: false,
            color: 'gray'
          }
        }
      )
    },
    async saveChanges() {
      for (let i = 0; i < this.currentFloorDesks.length; i += 1) {
        for (let j = 0; j < this.items.length; j += 1) {
          if (this.currentFloorDesks[i].id === this.items[j].id) {
            const newSnippet = `<rect x='${this.items[j].x}' y='${this.items[j].y}' width='${this.items[j].width}' height='${this.items[j].height}'/>`
            this.currentFloorDesks[i].svgSnippet = newSnippet
          }
        }
      }
      try {
        await OkkuApi.updateDesks(this.currentFloorDesks)
        this.updateAlert('success', this.$t('changesSaved'))
        const desks = await OkkuApi.getDesks()
        this.$store.commit(`common/${SET_DESKS}`, desks)
      } catch (error) {
        this.updateAlert('danger', error.message)
      } finally {
        setTimeout(() => {
          this.sendSuccess()
        }, 1000)
      }
    },
    sendSuccess() {
      this.editMode = false
      this.changesDone = false
      this.updateMode()
    },
    revertChange() {
      if (this.lastChangedItem !== null) {
        for (let i = 0; i < this.items.length; i += 1) {
          if (this.items[i].id === this.lastChangedItem) {
            if (this.lastChangedAction === 'MOVE') {
              this.items[i].x = this.lastChangedX
              this.items[i].y = this.lastChangedY
            }
            if (this.lastChangedAction === 'SCALE') {
              this.items[i].x = this.lastChangedX
              this.items[i].y = this.lastChangedY
              this.items[i].width = this.lastChangedWidth
              this.items[i].height = this.lastChangedHeight
            }
          }
        }
      }
    },
    updateAlert(variant, text) {
      this.$store.commit(`common/${SET_ALERT}`, {
        seconds: 5,
        text,
        variant
      })
    },
    cancelChanges() {
      if (this.changesDone) {
        this.$bvModal.show('modal-route')
      } else {
        this.editMode = false
        this.updateMode()
      }
    },
    onChange(val) {
      if (this.onChangeColorListener) {
        this.colors = val
        this.onChangeColorListener()
      }
    },
    onClickItem(event, item) {
      event.stopPropagation() // prevent parent from firing
      if (item.active) {
        return
      }
      // select item if not selected
      this.deactivateItems()
      item.active = true
      this.onChangeColorListener = () => {
        item.color = this.colors.hex
      }
      this.colors = defaultProps
      this.colors.hex = item.color
      this.activeItemIndex = this.items.indexOf(item)
    },
    deactivateItems() {
      this.items.forEach(i => {
        if (i) {
          i.active = false
        }
      })
    },
    onMouseDownRegion(event, item) {
      const offset = this.getMousePosition(event)
      if (item.active) {
        item.drag = {
          type: 'MOVE',
          x: item.x,
          y: item.y,
          mx: event.x,
          my: event.y,
          offsetX: offset.x - item.x,
          offsetY: offset.y - item.y
        }
      }
      this.movetarget = item
      this.lastChangedX = item.x
      this.lastChangedY = item.y
      this.changesDone = true
    },
    getMousePosition(evt) {
      const svg = document.getElementById('svgEdit')
      const CTM = svg.getScreenCTM()
      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d
      }
    },

    onMouseUp(event, element) {
      this.movetarget = null
    },

    onMouseMove(event) {
      if (this.movetarget === null) return
      const item = this.movetarget
      const t = this.tools
      this.lastChangedItem = item.id
      this.lastChangedAction = item.drag.type

      if (item.active && item.drag !== undefined) {
        if (item.drag.type === 'MOVE') {
          event.preventDefault()
          const coord = this.getMousePosition(event)
          item.x = coord.x - item.drag.offsetX
          item.y = coord.y - item.drag.offsetY
        }
        if (item.drag.type === 'SCALE') {
          if (item.drag.handleID === '1') {
            // TL resize handler
            item.x = Math.min(
              event.x - item.drag.mx + item.drag.x,
              item.drag.x + (item.drag.w - t.min_height)
            )
            item.y = Math.min(
              event.y - item.drag.my + item.drag.y,
              item.drag.y + (item.drag.h - t.min_height)
            ) // with y constraint
            item.width = Math.max(
              item.drag.w - event.x + item.drag.mx,
              t.min_height
            )
            item.height = Math.max(
              item.drag.h - event.y + item.drag.my,
              t.min_height
            )
          }
          if (item.drag.handleID === '3') {
            // TR resize handler
            item.y = Math.min(
              event.y - item.drag.my + item.drag.y,
              item.drag.y + (item.drag.h - t.min_height)
            ) // with y constraint
            item.width = Math.max(
              item.drag.w + event.x - item.drag.mx,
              t.min_height
            )
            item.height = Math.max(
              item.drag.h - event.y + item.drag.my,
              t.min_height
            )
          }
          if (item.drag.handleID === '7') {
            // BL
            item.x = Math.min(
              event.x - item.drag.mx + item.drag.x,
              item.drag.x + (item.drag.w - t.min_height)
            )
            item.width = Math.max(
              item.drag.w - event.x + item.drag.mx,
              t.min_height
            )
            item.height = Math.max(
              item.drag.h + event.y - item.drag.my,
              t.min_height
            )
          }
          if (item.drag.handleID === '9') {
            // BR
            item.width = Math.max(
              item.drag.w + event.x - item.drag.mx,
              t.min_height
            )
            item.height = Math.max(
              item.drag.h + event.y - item.drag.my,
              t.min_height
            )
          }
        }
      }
    },
    onMouseDownHandles(event, item) {
      const handleID = event.target.getAttribute('data-handleid')

      item.drag = {
        x: item.x,
        y: item.y,
        mx: event.x,
        my: event.y,
        w: item.width,
        h: item.height,
        type: 'SCALE',
        handleID
      }

      this.movetarget = item
      this.lastChangedX = item.x
      this.lastChangedY = item.y
      this.lastChangedWidth = item.width
      this.lastChangedHeight = item.height
      this.changesDone = true
    }
  }
}
</script>
<style scoped lang="scss">
svg {
  margin: 0 auto;
  width: 100%;
}

svg .ctrl-rect {
  fill: #ffffff;
  stroke: #222222;
  stroke-width: 1;
}

svg .ctrl-bounds {
  fill-opacity: 0;
  stroke: #222222;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-dasharray: 2, 4;
}
.canvas {
  text-align: center;
  overflow-x: auto;
}
.canvas svg {
  background: #fff;
}

.edit-toolbar {
  text-align: right;
  margin-top: -40px;
  margin-bottom: 20px;
  font-size: 0;
  .btn {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    height: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media all and (max-width: 500px) {
    text-align: left;
    margin-top: 10px;
  }
}
rect {
  cursor: pointer;
}
</style>
