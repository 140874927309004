var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[(_vm.isMapAvailable)?_c('section',{staticClass:"section-settings"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row topbar"},[_c('alert'),_c('h1',{staticClass:"title-1"},[_vm._v(" "+_vm._s(_vm.$t('freeDesks'))+" ")]),_c('div',{staticClass:"navigation-arrows-mobile"},[_c('a',{staticClass:"btn btn-outline-secondary",attrs:{"align-h":"center","variant":"primary"},on:{"click":function($event){return _vm.handleNavigation('prev')}}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left"}})],1),_c('b-button',{staticClass:"button-timepicker mx-1 rounded",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show('modal-changedate')}}},[(_vm.startTime && _vm.endTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.startTime,'timezone', _vm.buildingTimezone, 'ddd'))+" "+_vm._s(_vm._f("moment")(_vm.startTime, 'timezone', _vm.buildingTimezone, 'D MMMM HH:mm' ))+" - "+_vm._s(_vm._f("moment")(_vm.endTime,'timezone', _vm.buildingTimezone, 'HH:mm'))+" ")]):_vm._e(),_c('font-awesome-icon',{staticClass:"calendar-icon",attrs:{"icon":"calendar-alt"}})],1),_c('a',{staticClass:"btn btn-outline-secondary",attrs:{"align-h":"center","variant":"primary"},on:{"click":function($event){return _vm.handleNavigation('next')}}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)],1),_c('div',{staticClass:"floorAndEditButton"},[(_vm.floors.length > 1)?[_c('floors-dropdown',_vm._b({attrs:{"on-floor-selection":_vm.handleFloorSelection}},'floors-dropdown',{
                floors: _vm.floors,
                currentFloor: _vm.currentFloor
              },false))]:_vm._e(),(_vm.isDashboardAdmin)?[_c('b-button',{staticClass:"buttonMapEdit ml-1",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeMode()}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]:_vm._e()],2)],1),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('freeDesksSubtitle'))+" ")]),_c('div',{staticClass:"row justify-content-start justify-content-md-center"},[_c('div',{staticClass:"col-12"},[_c('b-tabs',{staticClass:"map-tabs",attrs:{"pills":""},model:{value:(_vm.activetab),callback:function ($$v) {_vm.activetab=$$v},expression:"activetab"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"layer-group"}})]},proxy:true}],null,false,3739762273)},[_c('b-card-text',[(_vm.allAttributes.length)?_c('div',{staticClass:"filters-row tar"},[_c('div',{staticClass:"floorplan-values"},[_c('attributes-dropdown',{attrs:{"options":_vm.allAttributes,"selected":_vm.attributeFilters},on:{"update-selection":_vm.updateSelectedFiltersForFloors}})],1)]):_vm._e(),(_vm.editMode)?[_c('SvgEditor',{attrs:{"current-floor":_vm.currentFloor,"desks":_vm.desks},on:{"updateMode":_vm.updateMode}})]:[_c('SvgMap',_vm._b({attrs:{"current-rooms":_vm.filteredCurrentRooms},on:{"updateSelectedObject":_vm.updateSelectedObject}},'SvgMap',{
                      currentFloor: _vm.currentFloor,
                      activeFloorDesks: _vm.activeFloorDesks,
                      selectedObject: _vm.selectedObject,
                      currentUser: _vm.currentUser,
                      desks: _vm.desks,
                      floors: _vm.floors,
                      rooms: _vm.rooms,
                      organisation: _vm.organisation,
                      updatedObjectId: _vm.updatedObjectId,
                      currentUserId: _vm.currentUserId
                    },false))]],2)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"list"}})]},proxy:true}],null,false,2370523794)},[_c('b-card-text',[_c('div',{staticClass:"filters-row tar d-flex flex-wrap align-items-center"},[_c('div',{staticClass:" d-flex align-items-center flex-wrap mr-2"},[(_vm.allAttributes.length)?_c('attributes-dropdown',{staticClass:"mr-2",attrs:{"options":_vm.allAttributes,"selected":_vm.attributeFilters},on:{"update-selection":_vm.updateSelectedFiltersForFloors}}):_vm._e(),_c('span',{staticClass:"floorplan-values--item mr-2",class:{
                        current: _vm.currentValue == 'desks'
                      },on:{"click":function($event){_vm.currentValue = 'desks'}}},[_vm._v(_vm._s(_vm.$t('desks')))]),_c('span',{staticClass:"floorplan-values--item",class:{
                        current: _vm.currentValue == 'rooms'
                      },on:{"click":function($event){_vm.currentValue = 'rooms'}}},[_vm._v(_vm._s(_vm.$t('rooms')))])],1),_c('input',{ref:"filter",staticClass:"form-control filter-input",attrs:{"id":"table-filter","type":"text","placeholder":_vm.$t('filterTable')},on:{"keyup":_vm.filterTable}})]),(
                    Object.keys(_vm.currentFloor).length &&
                      _vm.currentValue == 'desks'
                  )?_c('div',{staticClass:"table-responsive"},[_c('table',{ref:"table",staticClass:"table-style table-settings"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('desk'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('floor'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('room'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('reservedFor'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('reservationSubject'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('attributes'))+" ")]),_c('th')])]),_c('tbody',[_vm._l((_vm.activeFloorDesks),function(desk,idx){return [[(desk.reservable)?_c('tr',{key:((desk.id) + "__" + idx),attrs:{"data-val":desk.name +
                                ' ' +
                                desk.roomName +
                                ' ' +
                                desk.floorName +
                                ' ' +
                                (desk.status !== 'FREE'
                                  ? desk.reservedForUserFirstName +
                                    ' ' +
                                    desk.reservedForUserLastName +
                                    ' ' +
                                    desk.reservedForUser
                                  : '')}},[_c('td',{staticClass:"mobile-flex",attrs:{"data-label":_vm.$t('desk') + ':'}},[_c('span',[_c('span',{staticClass:"mobile-bigger"},[_vm._v(_vm._s(desk.name))]),_c('br'),_c('span',{staticClass:"mobile-show"},[_vm._v(_vm._s(desk.floorName))])]),(
                                  desk.status === _vm.OCCUPANCY.FREE &&
                                    !_vm.reservedRoomIds.includes(
                                      desk.roomId
                                    )
                                )?_c('button',{staticClass:"btn btn-secondary mobile-show",on:{"click":function($event){;(_vm.selectedObject = desk),
                                    _vm.$bvModal.show('modal-reserve')}}},[_vm._v(" "+_vm._s(_vm.$t('reserve'))+" ")]):_c('span',{staticClass:"desk-stat mobile-show",class:{
                                  'text-orange':
                                    desk.status ===
                                    _vm.OCCUPANCY.RESERVED,
                                  'text-red':
                                    desk.status ===
                                    _vm.OCCUPANCY.OCCUPIED
                                }},[_vm._v(_vm._s(_vm.getReservationLabel(desk))+" ")])]),_c('td',{staticClass:"mobile-hide",attrs:{"data-label":_vm.$t('floor') + ':'}},[_vm._v(" "+_vm._s(desk.floorName)+" ")]),_c('td',{staticClass:"mobile-hide",attrs:{"data-label":_vm.$t('room') + ':'}},[_vm._v(" "+_vm._s(desk.roomName)+" ")]),_c('td',{staticClass:"mobile-50",class:{
                                'mobile-hide':
                                  desk.reservedForUser == null
                              },attrs:{"data-label":_vm.$t('reservedFor') + ':'}},[_vm._v(" "+_vm._s(_vm.getDeskReservedFor(desk))+" ")]),_c('td',{staticClass:"mobile-50"},[(_vm.isAdmin)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                                  desk.reservationSubject
                                ),expression:"\n                                  desk.reservationSubject\n                                ",modifiers:{"hover":true,"right":true}}],staticClass:"texthide "},[_vm._v(" "+_vm._s(desk.reservationSubject)+" ")]):_vm._e(),(
                                  desk.visibleToOthers &&
                                    !_vm.isAdmin &&
                                    desk.privacySettings ===
                                      'show all details'
                                )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                                  desk.reservationSubject
                                ),expression:"\n                                  desk.reservationSubject\n                                ",modifiers:{"hover":true,"right":true}}],staticClass:"texthide "},[_vm._v(" "+_vm._s(desk.reservationSubject)+" ")]):_vm._e(),(
                                  desk.reservedForUser ===
                                    _vm.userDetail &&
                                    !_vm.isAdmin &&
                                    !desk.visibleToOthers &&
                                    desk.privacySettings ===
                                      'show all details'
                                )?_c('div',[_vm._v(" "+_vm._s(desk.reservationSubject)+" ")]):_vm._e()]),_c('td',{attrs:{"data-label":_vm.$t('attributes') + ':'}},[(desk.attributes)?_c('div',{staticClass:"d-flex flex-wrap "},[(desk.attributes)?_vm._l((desk.attributes),function(deskAttribute,index){return _c('base-pill',{key:("desk-attribue-" + index),attrs:{"item":deskAttribute}})}):_c('div',[_vm._v(" — ")])],2):_vm._e()]),_c('td',{staticClass:"mobile-50",class:{
                                'mobile-hide':
                                  desk.status === _vm.OCCUPANCY.FREE &&
                                  !_vm.reservedRoomIds.includes(
                                    desk.roomId
                                  )
                              },attrs:{"data-label":""}},[(
                                  desk.status === _vm.OCCUPANCY.FREE &&
                                    !_vm.reservedRoomIds.includes(
                                      desk.roomId
                                    )
                                )?_c('button',{staticClass:"btn btn-secondary mobile-hide",on:{"click":function($event){;(_vm.selectedObject = desk),
                                    _vm.$bvModal.show('modal-reserve')}}},[_vm._v(" "+_vm._s(_vm.$t('reserve'))+" ")]):_c('span',{staticClass:"desk-stat mobile-hide",class:{
                                  'text-orange':
                                    desk.status ===
                                    _vm.OCCUPANCY.RESERVED,
                                  'text-red':
                                    desk.status ===
                                    _vm.OCCUPANCY.OCCUPIED
                                }},[_vm._v(_vm._s(_vm.getReservationLabel(desk))+" "),(
                                    desk.status ===
                                      _vm.OCCUPANCY.RESERVED
                                  )?_c('font-awesome-icon',{staticClass:"mobile-hide",attrs:{"icon":"question-circle","title":_vm.$t('availability')},on:{"click":function($event){;(_vm.selectedObject = desk),
                                      _vm.$bvModal.show(
                                        'modal-availability'
                                      )}}}):_vm._e()],1)])]):_vm._e()]]})],2)])]):_vm._e(),(
                    Object.keys(_vm.currentFloor).length &&
                      _vm.currentValue == 'rooms'
                  )?_c('div',{staticClass:"table-responsive"},[_c('table',{ref:"table",staticClass:"table-style table-settings"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('room'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('floor'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('reserved'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('reservationSubject'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('attributes'))+" ")]),_c('th')])]),_c('tbody',[_vm._l((_vm.filteredCurrentRooms),function(room,idx){return [_c('tr',{key:((room.id) + "__" + idx),attrs:{"data-val":((room.name) + " " + (room.floorName) + " " + (room.reservedForUserFirstName) + " " + (room.reservedForUserLastName))}},[_c('td',{staticClass:"mobile-flex",attrs:{"data-label":_vm.$t('room') + ':'}},[_c('span',[_c('span',{staticClass:"mobile-bigger"},[_vm._v(_vm._s(room.name))]),_c('br'),_c('span',{staticClass:"mobile-show"},[_vm._v(_vm._s(room.floorName))])]),(
                                room.status === _vm.OCCUPANCY.FREE &&
                                  room.reservable
                              )?_c('button',{staticClass:"btn btn-secondary mobile-show",on:{"click":function($event){;(_vm.selectedObject = room),
                                  _vm.$bvModal.show('modal-reserve')}}},[_vm._v(" "+_vm._s(_vm.$t('reserve'))+" ")]):_vm._e(),(!room.reservable)?_c('span',{staticClass:"text-orange desk-stat mobile-show"},[_vm._v(_vm._s(_vm.$t('notReservable')))]):_vm._e(),(
                                room.status === _vm.OCCUPANCY.RESERVED
                              )?_c('span',{staticClass:"text-orange desk-stat mobile-show"},[_vm._v(_vm._s(_vm.$t('reserved'))+" "+_vm._s(_vm.$t('until'))+" "+_vm._s(_vm._f("moment")(room.reservationEndDatetime, 'timezone', _vm.buildingTimezone, 'HH:mm' ))+" ")]):_vm._e(),(
                                room.status === _vm.OCCUPANCY.OCCUPIED
                              )?_c('span',{staticClass:"text-red desk-stat mobile-show"},[_vm._v(_vm._s(_vm.$t('occupied'))+"/"+_vm._s(_vm.$t('reserved')))]):_vm._e()]),_c('td',{staticClass:"mobile-hide",attrs:{"data-label":_vm.$t('floor') + ':'}},[_vm._v(" "+_vm._s(room.floorName)+" ")]),_c('td',{staticClass:"mobile-50",class:{
                              'mobile-hide':
                                room.reservedForUser == null
                            },attrs:{"data-label":_vm.$t('reservedFor') + ':'}},[_vm._v(" "+_vm._s(_vm.getRoomReservedFor(room) || '')+" ")]),_c('td',{staticClass:"mobile-50"},[(_vm.isAdmin)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                                room.reservationSubject
                              ),expression:"\n                                room.reservationSubject\n                              ",modifiers:{"hover":true,"right":true}}],staticClass:"texthide"},[_vm._v(" "+_vm._s(room.reservationSubject)+" ")]):_vm._e(),(
                                room.visibleToOthers &&
                                  !_vm.isAdmin &&
                                  room.privacySettings ===
                                    'show all details'
                              )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                                room.reservationSubject
                              ),expression:"\n                                room.reservationSubject\n                              ",modifiers:{"hover":true,"right":true}}],staticClass:"texthide"},[_vm._v(" "+_vm._s(room.reservationSubject)+" ")]):_vm._e(),(
                                room.reservedForUser ===
                                  _vm.userDetail &&
                                  !_vm.isAdmin &&
                                  !room.visibleToOthers &&
                                  room.privacySettings ===
                                    'show all details'
                              )?_c('div',[_vm._v(" "+_vm._s(room.reservationSubject)+" ")]):_vm._e()]),_c('td',{attrs:{"data-label":_vm.$t('attributes') + ':'}},[(room.attributes)?_c('div',{staticClass:"d-flex flex-wrap"},[(room.attributes)?_vm._l((room.attributes),function(roomAttribute,key){return _c('base-pill',{key:("room-attribute-" + key),attrs:{"item":roomAttribute}})}):_c('div',[_vm._v(" — ")])],2):_vm._e()]),_c('td',{staticClass:"mobile-50",class:{
                              'mobile-hide':
                                room.status !==
                                  _vm.OCCUPANCY.RESERVED &&
                                room.status !== _vm.OCCUPANCY.OCCUPIED
                            },attrs:{"data-label":""}},[(
                                room.status === _vm.OCCUPANCY.FREE &&
                                  room.reservable
                              )?_c('button',{staticClass:"btn btn-secondary mobile-hide",on:{"click":function($event){;(_vm.selectedObject = room),
                                  _vm.$bvModal.show('modal-reserve')}}},[_vm._v(" "+_vm._s(_vm.$t('reserve'))+" ")]):_vm._e(),(!room.reservable)?_c('span',{staticClass:"text-orange desk-stat mobile-hide"},[_vm._v(_vm._s(_vm.$t('notReservable')))]):_vm._e(),(
                                room.status === _vm.OCCUPANCY.RESERVED
                              )?_c('span',{staticClass:"text-orange desk-stat mobile-hide"},[_vm._v(_vm._s(_vm.$t('reserved'))+" "+_vm._s(_vm.$t('until'))+" "+_vm._s(_vm._f("moment")(room.reservationEndDatetime, 'timezone', _vm.buildingTimezone, 'HH:mm' ))+" "),_c('font-awesome-icon',{staticClass:"mobile-hide",attrs:{"icon":"question-circle","title":_vm.$t('availability')},on:{"click":function($event){;(_vm.selectedObject = room),
                                    _vm.$bvModal.show(
                                      'modal-availability'
                                    )}}})],1):_vm._e(),(
                                room.status === _vm.OCCUPANCY.OCCUPIED
                              )?_c('span',{staticClass:"text-red desk-stat"},[_vm._v(_vm._s(_vm.$t('occupied'))+"/"+_vm._s(_vm.$t('reserved')))]):_vm._e()])])]})],2)])]):_vm._e()])],1)],1)],1)])])]):_c('section',[(_vm.loadingReady)?_c('h1',{staticClass:"no-map-available"},[_vm._v(" "+_vm._s(_vm.$t('mapNotAvailable'))+" ")]):_vm._e()]),_c('reservation-modal',_vm._b({attrs:{"is-busy":_vm.busy},on:{"reserve":_vm.reserveObject}},'reservation-modal',{
      selectedObject: _vm.selectedObject,
      startTime: _vm.startTime,
      endTime: _vm.endTime,
      showReservationSubject: _vm.showReservationSubject
    },false)),_c('reservation-timepicker-modal',_vm._b({attrs:{"raw-timeslots":_vm.buildingTimeslots,"show-hints":""}},'reservation-timepicker-modal',{ reservationSettings: _vm.reservationSettings, navigated: _vm.navigated, navigatedDate: _vm.navigatedDate },false)),_c('todays-availability-modal',_vm._b({},'todays-availability-modal',{
      selectedObject: _vm.selectedObject,
      startTime: _vm.startTime,
      endTime: _vm.endTime,
      buildingTimezone: _vm.buildingTimezone,
      buildingTimeslots: _vm.buildingTimeslots
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }